import * as M from 'materialize-css/dist/js/materialize.min.js'




/**
 * A Vue component that allows upload of a .smarts file.
 */
const SMARTSUploadBox = {
    name: 'SMARTSUploadBox',
    emits: ['response','disableMUpload'],
    data: () => ({ loading: false, file: null }),
    props: {
        /**
         * The URL used to send an HTTP POST request to the backend.
         * @type {string}
         */
        targetUrl: String,
        /**
         * The ID of the session storage.
         * @type {string}
         */
        sessionStorageId: String,
    },
      methods: {
          /**
           * Handles the uploaded file and sends it to the backend via HTTP POST request. On success, an event is emitted to signal success. In case of failure, an appropriate error message is shown. Disables the molecule upload button during calculation and displays a loading bar.
           */
        async readFile() {
            this.$emit('disableMUpload',true);
            this.loading = true;
            this._file = this.$refs.doc.files[0];
            const data = new FormData();
            if (this._file.size <= 1000000){ //prevent upload of large files beforehand
            if (this._file && this._file.name.includes(".smarts")) {
            data.append("file", this._file);
            data.append("sessionStorageId", this.sessionStorageId);
            const request = new Request(this.targetUrl, {
                    method: 'post',
                    body: data
                })

            try {
                    const response = await fetch(request);
                    const json = await response.json();
                    const ok = response.ok;

                    if(ok) {
                        this.$emit('response', json);
                    }
                    else {
                        throw new Error(json.error || String(response.status));
                    }
                } catch(err) {
                    this.loading = false;
                    this.$emit('disableMUpload',false);
                    let msg = (err instanceof Error) ? err.message : err;
                    if(!msg) msg = "Unknown reason"
                    M.toast({ html: `SMARTS set upload failed: ${msg}` });
                } finally {
                    this.loading = false;
                    this.$refs.doc.value = '';
                }
            }
                
            else{
                M.toast({ html: `SMARTS set upload failed: Please upload a .smarts file!` })
                this.loading = false;
                this.$emit('disableMUpload',false);
            }
            }
            else{
                M.toast({ html: `SMARTS set upload failed: File is too large. Please upload a file under 25 MB!` })
                this.loading = false; 
                this.$emit('disableMUpload',false);
            }
                
            }
  },
    template: `
    <div>
    <label>Upload SMARTS file (.smarts) to add to libraries</label>
    <form>
        <div class="form-group1">
            <input type="file" ref="doc" id='smartsuploadbox'  style="display: none;" @click="$event.target.value=''"  @change="readFile()" />
                <input v-show="!loading" type="button" value="Browse..." onclick="document.getElementById('smartsuploadbox').click();" style="height:25px; text-align: center; vertical-align: middle;"/> 
            <div v-show="loading">
                <span>Loading...</span>
                    <div class="loading-box">
                        <div class="loader"></div>
                    </div>
                </div>
        </div>
    </form>
</div>

`
};

export { SMARTSUploadBox };

