import * as M from 'materialize-css/dist/js/materialize.min.js'


/**
 * A Vue component that allows selection between the subset and the similarity mode.
 **/
const SimilarityMode = {
    name: 'SimilarityMode',
    emits: ['response'],
    data: () => ({mode: "SubsetOfFirst"}),
      methods: {
        /**
        * Check/uncheck the similarity and subset checkboxes. If a user has checked a previously unchecked box, emit an event. 
        * @param {Function} mode A string that is either "SubsetOfFirst" or "Similarity".
        **/
        async changeMode(mode) {
            if(this.mode==mode){
                if(mode=="SubsetOfFirst"){
                    document.getElementById('sub').checked=true;}
                else if(mode=="Similarity"){
                    document.getElementById('sim').checked=true;
                }
            }
            else if(this.mode!=mode){
                if(mode=="SubsetOfFirst"){
                    document.getElementById('sim').checked=false;
                    this.mode=mode;
                    this.$emit('response', 'Subset');
                }
                else if(mode=="Similarity"){
                    document.getElementById('sub').checked=false;
                 this.mode=mode; 
                 this.$emit('response', 'Similarity');
                }
            }
            
        }
                
  },
    template: `
    <div class="col s12">
        <label style="position: relative;display: inline-block; width:50%;"  >
            <input type="checkbox" class="filled-in" checked="true" id="sub" @click="changeMode('SubsetOfFirst')"/>
            <span @click="changeMode('SubsetOfFirst')" id="ssub" >Subset</span>
        </label>
        <label style="position: relative;display: inline-block;width:50%;padding-left: 13px" >
            <input type="checkbox" class="filled-in" id="sim" @click="changeMode('Similarity')"/>
            <span @click="changeMode('Similarity')" id="ssim" >Similarity</span>
        </label>
    </div>

`
};

export { SimilarityMode };

