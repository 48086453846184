import ClipboardJS from 'clipboard';

const Copyable = {
    name: 'copyable',
    props: {
        label: String,
        text: String
    },
    mounted() {
        this._clipboardJS = new ClipboardJS(this.$refs.clipboardButton);
    },
    beforeUnmount() {
        this._clipboardJS.destroy();
    },
    template: `
<label>
    {{ currentlabel() }}
    <div>
        <input class="with-icon-btn" type="text" disabled="disabled" :value="text" ref="input" />
        <button class="icon-btn icon-btn-right btn-flat waves-effect"
            ref="clipboardButton"
            :data-clipboard-text="text">
            <i class="material-icons">content_copy</i>
        </button>
    </div>
</label>
    `,
    methods: {
        currentlabel() {
            var mode = "Subset";
            const firstmarker = document.querySelector(".visiblemarkers");
            if (firstmarker){
            mode= firstmarker.style.visibility=="hidden" ? "Similarity" : "Subset"; 
                
            };
                if(mode == "Subset"){
                    if(this.$props.label=="Source pattern:"){
                    return "Source pattern:";
                    }
                    else if(this.$props.label=="Target pattern:") {
                        return "Target pattern:";
                        
                    }
                }
                else if(mode == "Similarity"){
                    if(this.$props.label=="Source pattern:"){
                    return "Pattern one:";
                    }
                    else if(this.$props.label=="Target pattern:"){
                        return "Pattern two:";
                        
                    }
                    
                } 
                return this.$props.label;
        }
        
    },
};


const Pluralize = {
    props: {
        count: {
            type: Number,
            validator: (num) => (num | 0) == num  // verify that it's an integer
        },
        string: String,
        pluralString: String
    },
    template: `{{ count }} {{ count === 1 ? string : pluralString_ }}`,
    computed: {
        pluralString_() {
            return this.pluralString ? this.pluralString : this.string + 's';
        }
    }
};


export { Copyable, Pluralize };
