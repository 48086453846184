import * as M from 'materialize-css/dist/js/materialize.min.js'

/**
 * A Vue component for uploading molecule files.
 **/
const UploadBox = {
    name: 'uploadBox',
    emits: ['response','disableSUpload','updateFilename'],
    data() {
        return {
            loading: false,
            files: null,
        };
    },
    props: {
        /**
         * The URL used to send an HTTP POST request to the backend.
         * @type {string}
         */
        targetUrl: String,
        /**
         * The ID of the session storage.
         * @type {string}
         */
        sessionStorageId: String,
    },
    methods: {
        /**
        * Sends a file to the backend via an HTTP request to calculate matches, communicates success or failure. Disables the smarts upload button during calculation and displays a loading bar.
        * @param {Function} event An event containing the user-uploaded file.
        **/
        async uploadFile (event) {
            this.$emit('disableSUpload',true);
            this.loading = true;
            const { target } = event;
            
            this._file=target.files[0];
            const data = new FormData();
            if (this._file.size <= 1000000){ //prevent upload of very large files beforehand
            if (this._file && (this._file.name.includes(".smi") || this._file.name.includes(".SMILES"))) {
            data.append('file', this._file, this._file.name);
            data.append("sessionStorageId", this.sessionStorageId);
            const request = new Request(this.targetUrl, {
                method: 'post',
                body: data
            })

            try {
                const response = await fetch(request);
                const json = await response.json();
                const ok = response.ok;

                if(ok) {
                    this.$emit('response', json);
                    this.$emit('updateFilename',this._file.name);
                    M.toast({ html: 'Molecule set upload finished!' })
                }
                else {
                    throw new Error(json.error || String(response.status));
                }
            } catch(err) {
                this.loading = false;
                this.$emit('disableSUpload',false);
                let msg = (err instanceof Error) ? err.message : err;
                if(!msg) msg = "Unknown reason"
                M.toast({ html: `Molecule set upload failed: ${msg}` });
                
            } finally {
                this.loading = false;
                target.value='';
            }
            }
                
            else{
                M.toast({ html: `Molecule set upload failed: Please upload a .smi or .smiles file!` })
                this.$emit('disableSUpload',false);
                this.loading = false;
            }
            }
            else{
                M.toast({ html: `Molecule set upload failed: File is too large. Please upload a file under 25 MB!` })
                this.$emit('disableSUpload',false);
                this.loading = false;                
            }
        }
    },
    template: `
<div>
    <label>Upload molecule file (.smi, .SMILES) to match to SMARTSs</label>
    <form>
        <div class="form-group1">
            <div class="upload-button-container">
            <input type="file" id='uploadbox'  style="display: none;" @change="uploadFile" />
                <input v-show="!loading" type="button" value="Browse..." onclick="document.getElementById('uploadbox').click();" style="height:25px; text-align: center; vertical-align: middle;"/> 
                <a v-show="!loading" id="displayedfilename" style="vertical-align: middle; color:black;"></a>
                </div>
                <div v-show="loading">
                    <span>Loading...</span>
                        <div class="loading-box">
                            <div class="loader"></div>
                        </div>
                    </div>
        </div>
    </form>
</div>
`
};

export { UploadBox };
