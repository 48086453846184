import _ from 'lodash';

import * as d3Force from 'd3-force';
import * as d3Drag from 'd3-drag';
import * as d3Selection from 'd3-selection';
import * as d3Zoom from 'd3-zoom';
const d3 = Object.assign({}, d3Force, d3Drag, d3Selection, d3Zoom);

import { SimulatedGraph, Graph } from '../graph.js'

/**
 * Hacky function for getting the ID of either an HTML element representing a SMARTS node or edge,
 * or an object representing a SMARTS node or edge. Required to be this way so that it can be
 * passed to d3-force as is.
 * @param {Object} obj a Node, Edge, or corresponding HTML element of either of these
 * @returns The unique ID associated with the given obj
 */
function getId(obj) {
    return obj ? obj.id : this.dataset.id;
}

/**
 * Gets an HTML id from a given color. Only works properly for colors in hex format.
 * Behavior is undefined for other color formats.
 *
 * Used to link up ArrowheadMarkers and SmartsEdges.
 * @param {String} color The color to use.
 * @returns The HTML id that can be attached to an element.
 */
function getColorIdFromColor(color) {
    return color.substring(1);
}

/**
 * Vue component that renders an SVG marker for an arrowhead.
 * Used implicitly by the SmartsSubsetEdge component.
 */
const ArrowheadMarker = {
    name: 'ArrowheadMarker',
    props: {
        color: {
            type: String,
            default: () => ''
        },
        refX: {
            type: Number,
            default: () => 14
        },
        size: {
            type: Number,
            default: () => 25
        },
        id: {
            type: String
        }
    },
    template: `
<marker class="visiblemarkers"
    :id="id" :refX="refX" refY="0" viewBox="0 -5 10 10"
    markerUnits="userSpaceOnUse" :markerWidth="size" :markerHeight="size"
    xoverflow="visible" orient="auto"
>
    <path d="M 0,-5 L 10,0 L 0,5 z" :fill="color" />
</marker>`
};


/**
 * Vue component that renders multiple SVG markers for an arrowhead, given a set of colors.
 * Used implicitly by the SmartsSubsetEdge component.
 */
const ArrowheadMarkers = {
    name: 'ArrowheadMarkers',
    components: { ArrowheadMarker },
    props: {
        colors: {
            type: Array,
            validator: (colors) => {
                return _.every(colors, (color) => color.startsWith('#'));
            }
        }
    },
    computed: {
        ids() {
            return _.fromPairs(
                _.map(this.colors,
                    (color) => [color, `arrowhead-${getColorIdFromColor(color)}`]
            ));
        }
    },
    template: `
<arrowhead-marker v-for="color in colors"
    :color="color"
    :id="ids[color]"
/>`
};


/**
 * Vue component that renders a single SMARTS node.
 */
const SmartsNode = {
    name: 'SmartsNode',
    emits: ['nodeDrag', 'nodeDragStart', 'nodeDragEnd'],
    props: {
        node: Object,
        classFn: Function,
        colorFn: Function,
        strokeOpacityFn: Function,
        styleFn: Function
    },
    mounted() {
        const nodeDrag = d3.drag();
        nodeDrag
            .on("drag", (event) => this.$emit('nodeDrag', { event: event, node: this.node }))
            .on("start", (event) => this.$emit('nodeDragStart', { event: event, node: this.node }))
            .on("end", (event) => this.$emit('nodeDragEnd', { event: event, node: this.node}));
        d3.select(this.$refs.node).call(nodeDrag);
    },
    template: `
<circle
    ref="node"
    class="node"
    :class="classFn(node)"
    r="15"
    :fill="colorFn(node)"
    :stroke="strokeOpacityFn(node)"
    :style="styleFn(node)"
/>
`
};


/**
 * Vue component that renders a collection of SMARTS nodes.
 */
const SmartsNodes = {
    name: 'SmartsNodes',
    components: { SmartsNode },
    emits: ['nodeClick', 'nodeHover', 'nodeDblClick', 'nodeDrag', 'nodeDragStart', 'nodeDragEnd'],
    props: {
        nodes: Array,
        classFn: Function,
        colorFn: Function,
        strokeOpacityFn: Function,
        styleFn: Function
    },
    template: `
<g class="smarts-nodes">
    <smarts-node
        v-for="node in nodes"
        :key="node.id"
        :data-id="node.id"
        :node="node"
        :classFn="classFn"
        :colorFn="colorFn"
        :strokeOpacityFn="strokeOpacityFn"
        :styleFn="styleFn"
        @click="$emit('nodeClick', { event: $event, node: node })"
        @dblclick="$emit('nodeDblClick', { event: $event, node: node })"
        @mouseover="$emit('nodeHover', { event: $event, node: node })"
        @nodeDrag="$emit('nodeDrag', $event)"
        @nodeDragStart="$emit('nodeDragStart', $event)"
        @nodeDragEnd="$emit('nodeDragEnd', $event)"
    />
</g>
`,
};


/**
 * Vue component that renders a single directed SMARTS subset edge.
 */
const SmartsSubsetEdge = {
    name: 'SmartsSubsetEdge',
    props: {
        edge: Object,
        classFn: Function,
        colorFn: Function,
        styleFn: Function
    },
    template: `
<g class="composite-line" :class="classFn(edge)">
    <line ref="lineHelper"
        class="edge subset hoverhelper"
        stroke="black"
        :stroke-width="20"
        :data-id="edge.id" />
    <line ref="lineReal"
        class="edge subset real"
        :marker-end="markerEnd"
        :stroke="colorFn(edge)"
        :style="styleFn(edge)"
        :data-id="edge.id" />
</g>
`,
    computed: {
        markerEnd: function() {
            if(this.edge.type === 'equal') return '';
            else {
                const colorId = getColorIdFromColor(this.colorFn(this.edge));
                return `url(#arrowhead-${colorId})`;
            }
        }
    }
};

/**
 * Vue component that renders a collection of directed SMARTS subset edges.
 */
const SmartsSubsetEdges = {
    name: 'SmartsSubsetEdges',
    components: { SmartsSubsetEdge },
    emits: ['edgeHover', 'edgeClick'],
    props: {
        edges: Object,
        classFn: Function,
        colorFn: Function,
        styleFn: Function
    },
    template: `
<g class="subset-edges">
    <smarts-subset-edge v-for="edge in edges"
        :key="edge.id"
        :edge="edge"
        :classFn="classFn"
        :colorFn="colorFn"
        :styleFn="styleFn"
        @mouseover="$emit('edgeHover', { event: $event, edge: edge })"
        @click="$emit('edgeClick', { event: $event, edge: edge })"
    />
</g>
`

};


const perObjectFn = {
    type: Function,
    validator: (fn) => fn.length == 1
};

/**
 * Vue component that renders a directed SMARTS graph as an interactive SVG.
 */
const SmartsGraph = {
    name: 'SmartsGraph',
    emits: ['nodeHover', 'edgeHover'],
    components: {
        ArrowheadMarkers, SmartsNodes, SmartsSubsetEdges
    },
    /** The props of this component. */
    props: {
        /** The Graph instance to render */
        graph: Graph,

        /**
         * A function that returns a (CSS class) -> (boolean) mapping given a node.
         * Only if the boolean is true, the class will be added to the node.
         * No classes by default.
         */
        nodeClassFn:   { ...perObjectFn, default: (node) => {} },
        /** A function that returns a color given a node (white by default). */
        nodeColorFn:   { ...perObjectFn, default: (node) => 'white' },
        /**
         * A function that returns a (key)->(value) CSS style mapping given a node.
         * No styles by default
         */
        nodeStyleFn:   { ...perObjectFn, default: (node) => {} },
        /**
         * A function that returns a value for the outline opacity. Black by default.
         */
        nodeStrokeOpacityFn: { ...perObjectFn, default: (node) => "black" },
        /**
         * A function that determines if a node is active
         * (shown, included in the layout and force simulation, visited during DFS)
         */
        nodeActiveFn:  { ...perObjectFn, default: (node) => true },

        /**
         * A function that returns a (CSS class) -> (boolean) mapping given an edge.
         * Only if the boolean is true, the class will be added to the edge.
         * No classes by default.
         */
        edgeClassFn:   { ...perObjectFn, default: (node) => {} },
        /** A function that returns a color given a node (black by default). */
        edgeColorFn:   {
            ...perObjectFn,
            default: (edge) => 'black',
            validator: (fn) => fn.length == 1 && fn.colors instanceof Array,
        },
        /**
         * A function that returns a (key)->(value) CSS style mapping given an edge.
         * No styles by default
         */
        edgeStyleFn:   { ...perObjectFn, default: (edge) => {} },
        /**
         * A function that determines if an edge is active
         * (shown, included in the layout and force simulation, traversed during DFS)
         */
        edgeActiveFn:  { ...perObjectFn, default: (edge) => true },
    },
    data() {
        return {
            isObjectSelectionFixed: false,
            mouseDown: false,
            startPoint: null,
            endPoint: null,
            transform: null,
            ticknum:0,
        };
    },
    emits: ['edgeClick', 'nodeClick', 'edgeHover', 'nodeHover', 'backgroundClick', 'backgroundMousedown', 'updateNodeSelection'],
    template: `
    <div style="position: absolute; top: 0; left: 0; width: 100%; height: 100%;">
<svg ref="graphSvg" width="100%" height="100%" class="vue-drag-select" @click.self="handleBackgroundClick" @mousedown.self="handleBackgroundMousedown">
    <defs>
        <arrowhead-markers :colors="edgeColorFn.colors" />
        <slot></slot>
    </defs>
    <g ref="graphSvgMasterGroup" @click.self="handleBackgroundClick" >
        <rect v-if="mouseDown" class="vue-drag-select-box" :style="selectionBoxStyling"></rect>
        <smarts-subset-edges :edges="activeEdges"
            :classFn="edgeClassFn"
            :colorFn="edgeColorFn"
            :styleFn="edgeStyleFn"
            @edgeClick="$emit('edgeClick', $event)"
            @edgeHover="$emit('edgeHover', $event)"
            @click.self="handleBackgroundClick"
        />
        <smarts-nodes :nodes="activeNodes"
            :classFn="nodeClassFn_"
            :colorFn="nodeColorFn"
            :strokeOpacityFn="nodeStrokeOpacityFn"
            :styleFn="nodeStyleFn"
            @nodeClick="$emit('nodeClick', $event)"
            @nodeHover="$emit('nodeHover', $event)"
            @nodeDblClick="handleNodeDblClick($event)"
            @nodeDrag="handleNodeDrag($event)"
            @nodeDragStart="handleNodeDragStart($event)"
            @nodeDragEnd="handleNodeDragEnd($event)"
            @click.self="handleBackgroundClick"
        />
    </g>
</svg>
</div>
`,
    /** Computed props of this component */
    computed: {
        /**
         * The currently active edges (determined by the edgeActiveFn prop)
         */
        activeEdges() {
            return _.filter(this.graph.edges, this.edgeActiveFn);
        },
        /**
         * The currently active nodes (determined by the nodeActiveFn prop)
         */
        activeNodes() {
            return _.filter(this.graph.nodes, this.nodeActiveFn);
        },
        /**
         * Merges external nodeClassFn behavior with component-internal behavior
         */
        nodeClassFn_() {
            return (node) => {
                return { ...this.nodeClassFn(node), 'node-fixed': node.__dragFixed }
            };
        },
        /**
         * Calculates position of selection box if drag select is being applied
         */
        selectionBox() {

            if (!this.mouseDown || !this.startPoint || !this.endPoint) return {};


            const clientRect = this.$el.getBoundingClientRect();
            const scroll = this.getScroll();


            const left =
                Math.round((Math.min(this.startPoint.x, this.endPoint.x) -
                this.transform.x)*1/this.transform.k);
            const top =
                Math.round((Math.min(this.startPoint.y, this.endPoint.y) -
                this.transform.y)*1/this.transform.k);
            const width = Math.abs(this.startPoint.x - this.endPoint.x)*(1/this.transform.k);
            const height = Math.abs(this.startPoint.y - this.endPoint.y)*(1/this.transform.k);

            return {
                left,
                top,
                width,
                height
            };
            },
        /**
         * Returns the styles to be applied to the box
         */
        selectionBoxStyling() {
            if (!this.mouseDown || !this.startPoint || !this.endPoint) {
                return { width: "0px", height: "0px" };
            }
            const { left, top, width, height } = this.selectionBox;
            return {
                fill: "rgba(39, 165, 157, 0.1)",
                x: `${left}px`,
                y: `${top}px`,
                width: `${width}px`,
                height: `${height}px`
            };
            
        },
    },
    /**
     * Sets up a watcher to reinitialize the graph simulation if the graph or the active edges
     * change (not deep-watching, so only triggers when these are replaced)
     */
    beforeMount() {
        this.$watch(() => [this.graph, this.activeEdges], (newVal) => {
            this.initGraphSimulation();
        }, { immediate: true });
    },
    /**
     * Sets up the zoom functionality for the graph
     */
    mounted() {
        this.initGraphZoom();

    },
    /**
     * The methods of this component
     */
    methods: {
        /**
         * Initialize the graph simulation and return the SimulatedGraph instance
         */
        initGraphSimulation() {
            this.initSimulation();
            this._simulatedGraph = new SimulatedGraph(
                this.graph, this._simulation, this.edgeActiveFn, this.nodeActiveFn
            );
            return this._simulatedGraph;
        },
        /**
         * Set up and store a simulation with the devised forces and parameters.
         */
        initSimulation() {
            if(this._simulation) { this._simulation.stop(); }
            let ticknum = 0;

            this._simulation = d3.forceSimulation()
                .force('charge', d3.forceManyBody()
                    .strength(node => {
                        return -30 * Math.log(1 + node.incidentEdges.length);
                    }))
                .force('link', d3.forceLink()
                    .id(edge => edge.id)
                    .distance(edge => edge.type === 'equal' ? 75 : 150)
                    .strength(1)
                    .iterations(3))
                .force('collision', d3.forceCollide()
                    .radius(22.5))
            .on('tick', this.onSimulationTick.bind(this));
            //.on('end', function(){alert("end");});
            // implicitly use restartSimulation's default values for alpha & alphaDecay
            this.ticknum = 0;
            this.restartSimulation();
        },
        /**
         * Restart the simulation, optionally setting a new alpha and alphaDecay.
         * @param {Number} alpha The 'temperature' of the simulation (see d3-force docs)
         * @param {Number} alphaDecay The 'temperature decay speed' of the simulation (see d3-force)
         */
        restartSimulation(alpha = 2, alphaDecay = 0.15) { //was 2
            this._simulation.alpha(alpha).alphaDecay(alphaDecay).velocityDecay(0.5).restart();
        },
        /**
         * Runs on each tick of the force simulation. Updates node and edge positions efficiently.
         * Note that this function somewhat bypasses Vue render logic and only works when the getId
         * function is ensured to work properly to link up exactly the correct nodes with exactly
         * the correct SMARTS objects.
         */
        onSimulationTick() {
            
                const root = d3.select(this.$refs.graphSvg);
                // update node positions
                root.selectAll('circle.node').data(this._simulatedGraph.simulacrum.nodes, getId)
                    .attr('cx', node => node.x)
                    .attr('cy', node => node.y);
                // update edge positions (real and hoverhelper edges)
                _.each(['line.subset.real', 'line.subset.hoverhelper'], selector => {
                    root.selectAll(selector).data(this._simulatedGraph.simulacrum.edges, getId)
                        .attr('x1', edge => edge.source.x)
                        .attr('x2', edge => edge.target.x)
                        .attr('y1', edge => edge.source.y)
                        .attr('y2', edge => edge.target.y);
                });
            
        },

        /**
         * Sets up panning & zooming for the graph.
         */
        initGraphZoom() {
            let graphSvgElement = this.$refs.graphSvg;
            let masterGroupElement = d3.select(this.$refs.graphSvgMasterGroup);
            let zm = d3.zoom()
                                .filter(() =>{
                                    if(event.shiftKey || event.button != 0)
                                    {
                                        return false;
                                        
                                    }
                                    return true;
                                    
                                })
                                .scaleExtent([0.1, 2]);
            const initialScale = 0.25;
            const width = graphSvgElement.clientWidth;
            const height = graphSvgElement.clientHeight;
            d3.select(graphSvgElement)
                .call(zm
                      .on('zoom', event => masterGroupElement.attr("transform", event.transform)))
                .on('dblclick.zoom', null)
                .call(zm.scaleTo, initialScale)
                .call(zm.translateTo, -width/2 * initialScale, -height/2 * initialScale);
            
            
        },

        /**
         * Handles the user dragging a node, by removing all other nodes from the
         * force simulation.
         */
        handleNodeDragStart({ node, event }) {
            const { id } = node;
            const { simulacrum } = this._simulatedGraph;
            const simNode = simulacrum.getNodeById(id);
            simNode.fx = simNode.x;
            simNode.fy = simNode.y;
            let ccEdges = [];
            
            this._simulatedGraph.simulacrum.runDFS(
                simNode, 'all', 0,  //was Infinity when nodes in same CC were dragged along
                (node) => { },
                (edge) => { ccEdges.push(edge) },
                null,
                this.edgeActiveFn);

            this._simulation.nodes([simNode]);
            this._simulation.force('link').links(ccEdges);
        },
        /** Handles the user dragging a node, by taking its dragged position as fixed. */
        handleNodeDrag({ node, event }) {
            const { id } = node;
            const { x, y } = event;
            const { simulacrum } = this._simulatedGraph;
            const simNode = simulacrum.getNodeById(id);
            simNode.fx = x;
            simNode.fy = y;
            this.restartSimulation(0, 1);
        },

        handleNodeDragEnd(nodeId) {
        },

        /** Emits an event when the user clicks on the background. */
        handleBackgroundClick(event) {
            this.$emit('backgroundClick', event);
        },
        /** Returns scroll values to calculate selection box dimensions and position. */
        getScroll() {
        if (typeof document === "undefined") {
            return {
            x: 0,
            y: 0
            };
        }

        return {
            x:
            this.$el.scrollLeft ||
            document.body.scrollLeft ||
            document.documentElement.scrollLeft,
            y:
            this.$el.scrollTop ||
            document.body.scrollTop ||
            document.documentElement.scrollTop
        };
        },
        /**
         * Handles the user clicking and holding the mouse on the background while the shift key is held down. Stores the clicked point.
         */
        handleBackgroundMousedown(event){
            
            
               if (event.shiftKey) {
                   this.transform = d3.zoomTransform(d3.select(this.$refs.graphSvgMasterGroup).node());
                   this.mouseDown = true;
                   this.startPoint = {
                    x: event.pageX,
                    y: event.pageY
                };
                window.addEventListener("mouseup", this.onMouseUp);
                window.addEventListener("mousemove", this.onMouseMove);
                
               }
        },
        /**
         * Handles the user dragging the mouse while holding the shift key. Stores the point the mouse is dragged to.
         */
        onMouseMove(event) {
                  if (this.mouseDown) {
                        this.endPoint = {
                        x: event.pageX,
                        y: event.pageY
                        };
                  }
        },
        /**
         * Handles the user releasing the mouse button. Emits an event with the nodes that have been selected 
         * with the selection box.
         */
        onMouseUp(event) {
            window.removeEventListener("mouseup", this.onMouseUp);
            window.removeEventListener("mousemove", this.onMouseMove);
            
            const { simulacrum } = this._simulatedGraph;
            const boxSelectedNodes = [];
                    
            _.each(this.graph.nodes, node => {
                    const simNode = simulacrum.getNodeById(node.id);
                    const boxStartX = (this.startPoint.x-this.transform.x)/this.transform.k
                    const boxStartY = (this.startPoint.y-this.transform.y)/this.transform.k
                    const boxEndX = (this.endPoint.x-this.transform.x)/this.transform.k
                    const boxEndY = (this.endPoint.y-this.transform.y)/this.transform.k                    
                    if(((boxStartX > simNode.x &&  simNode.x > boxEndX) || (boxEndX > simNode.x && simNode.x > boxStartX)) && ((boxStartY > simNode.y && simNode.y > boxEndY) || (boxEndY > simNode.y && simNode.y > boxStartY))){
                        boxSelectedNodes.push(node);
                    };
                        
            });
            this.$emit('updateNodeSelection', boxSelectedNodes);
            this.mouseDown = false;
            this.startPoint = null;
            this.endPoint = null;
            
                    

            },
    },
};


export { SmartsGraph, ArrowheadMarker };
