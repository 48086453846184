/////////////// LIBRARY IMPORTS ////////////////////////////////////////////////

// import all -- see https://lodash.com/per-method-packages
import _ from 'lodash';

 // import all -- unable to take specific feature subset
import * as Vue from 'vue';//'vue/dist/vue.esm-bundler';
//import { BootstrapVue, IconsPlugin } from 'bootstrap-vue'

import * as d3ScaleChromatic from 'd3-scale-chromatic';
import * as d3Interpolate from 'd3-interpolate';
import * as d3Scale from 'd3-scale';
const d3 = Object.assign({}, d3ScaleChromatic, d3Interpolate, d3Scale);

import * as M from 'materialize-css/dist/js/materialize.min.js';

import { v4 as uuidv4 } from 'uuid';



/////////////// COMPONENT IMPORTS //////////////////////////////////////////////

import { Graph } from './graph.js'
import { SmartsGraph, ArrowheadMarker } from './components/smarts-graph.js';
import { InfoBox } from './components/info.js';
import { RangeSlider } from './components/range-slider.js';
import { NodeFix } from './components/node-fix.js';
import { UploadBox } from './components/upload-box.js';
import { SMARTSUploadBox } from './components/smarts-upload-box.js';
import { InfoButton, AboutButton, ControlsButton, SimilarityInfoButton } from './components/modals.js';
import { SimilarityMode } from './components/similarity-mode.js'

/////////////// STYLE IMPORTS //////////////////////////////////////////////////

import 'materialize-css/sass/materialize.scss';
import './style.css';

/////////////// HELPER FUNCTIONS ///////////////////////////////////////////////

const kellyColors =
      ['#F2F3F4', '#222222', '#F3C300', '#875692', '#F38400',
       '#A1CAF1', '#BE0032', '#C2B280','#848482', '#008856',
       '#E68FAC', '#0067A5', '#F99379', '#604E97', '#F6A600',
       '#B3446C', '#DCD300', '#882D17', '#8DB600', '#654522',
       '#E25822', '#2B3D26'];

/**
 * Returns all SMARTS libraries referred to by a graph's nodes. Libraries that are part of the default libraries are sorted alphabetically by name, followed by any user-uploaded libraries.
  * @param {Graph|Object} graph an object containing nodes that all have a 'library' property
 */
function getLibrariesFromGraph(graph) {
    const { nodes } = graph;
    const defaultLibraries = ["BMS","Dundee","Glaxo","Inpharmatica","Lint","MLSMR","PAINS","SMARTCyp","SureChEMBL"];
    var usedDefaultLibraries=[];
    var userLibraries=[];

    Object.keys(_.uniq(_.map(nodes, 'library'))).forEach(function(key) {
    var currentLibrary= _.uniq(_.map(nodes, 'library'))[key];
    if(defaultLibraries.includes(currentLibrary)){
        usedDefaultLibraries.push(currentLibrary);
    }
    else{
        userLibraries.push(currentLibrary);
    }

    });
    return _.sortBy(usedDefaultLibraries).concat(userLibraries);
}

/**
 * Returns all SMARTS properties referred to by a graph's nodes, sorted alphabetically by name.
  * @param {Graph|Object} graph an object containing nodes that all have an 'annotation' property
 */
function getPropertiesFromGraph(graph){
    const { nodes } = graph;
    var graphProperties=[];
    Object.keys(_.uniq(_.map(nodes, 'annotation'))).forEach(function(key) {
    var currentProperties= (_.uniq(_.map(nodes, 'annotation'))[key]).split(" ");
    _.each(currentProperties, (property) => { if(!graphProperties.includes(property)){if(property=="" || property=="azacycles_A" || property=="azacycles_B" ){property="No annotation";}graphProperties.push(property)}; });


    });    
    
    return _.sortBy(graphProperties);
}

/**
 * Returns an object that maps SMARTS libraries to colors, by order in the libraries array parameter
 * (using the Kelly colors by default).
 * @param {Array} libraries The libraries to map colors for
 * @param {Array} colors (optional) The colors to map to. Kelly colors, if not given.
 * @returns An object mapping libraries (strings) to colors (strings).
 */
function librariesToColorMapping(libraries, colors = kellyColors) {
    return _.chain(libraries)
        .map((name, i) => [name, colors[i]])
        .fromPairs()
        .value();
}

/**
 * Verifies if a SMARTS node matches a search regexp. A falsy (empty/null) regex will lead to a
 * truthy result.
 * @param {Node} node A node object (generally, an object with a name property).
 * @param {Regexp} regexp A Regexp to match against the node's name.
 * @returns true if the regex matches or if the regex is falsy (empty/null). false otherwise.
 */
function matchesSearchRegexp(node, regexp) {
    return !regexp || regexp.test(node.name);
}

/////////////// DEFINE THE APP DESCRIPTION /////////////////////////////////////

/**
 * The main component of the SMARTSexplore app.
 */
const appDescription = {
    name: 'SMARTSexplore',
    components: { SmartsGraph, InfoBox, ArrowheadMarker },
    template: `
<div class="smartsexplore-app" :class="{ 'night-mode': settings.nightMode }">
    <smarts-graph
        ref="graph"
        :graph="graph"
        :nodeColorFn="nodeColorFn"
        :nodeStrokeOpacityFn="nodeStrokeOpacityFn"
        :nodeClassFn="nodeClassFn"
        :nodeActiveFn="nodeActiveFn"
        :edgeColorFn="edgeColorFn"
        :edgeStyleFn="edgeStyleFn"
        :edgeClassFn="edgeClassFn"
        :edgeActiveFn="edgeActiveFn"
        @nodeClick="handleNodeClick"
        @nodeHover="handleNodeHover"
        @edgeClick="handleEdgeClick"
        @edgeHover="handleEdgeHover"
        @backgroundClick="handleGraphBackgroundClick"
        @updateNodeSelection="updateNodeSelection"
    >
        <arrowhead-marker id="arrowhead-highlight" />
    </smarts-graph>
    <settings v-model="settings"
        :libraryToColor="libraryToColor"
        :colorMap="similarityToColor"
        :matchesLoaded="matchesLoaded"
        :selectedObject="selectedObject"
        :graph="graph"
        @fileUploadResponse="handleFileUploadResponse"
        @SMARTSfileUploadResponse="handleSMARTSFileUploadResponse"
        @similarityChangeResponse="handleSimilarityChangeResponse"
        @updateSelectedObject="updateSelectedObject"
    />
    <info-box
        :obj="selectedObject"
        :showMatches="matchesLoaded"
    />
</div>
    `,
    /**
     * Generates initial data for the SMARTSexplore app.
     */
    data() {
        return {
            /** The Graph object */
            graph: this.initGraph([], []),
            /** The Regexp object to use for SMARTS searching */
            searchRegexp: null,
            /** The settings object, which is passed to the settings component */
            settings: {
                /** The current library selection, mapping library names to booleans */
                librarySelection: {},
                /** The current node selection containing node objects */
                nodeSelection: [],
                /** The search string, which is parsed as a Regexp */
                searchString: '',
                /** Denotes if something is wrong with the search string the user put in */
                _searchStringError: false,
                /** Contains information about how to display edge similarity */
                edgeSimilarity: {
                    /**
                     * The colormap to use -- the string should correspond to a property available
                     * on the d3 object as 'interpolate'+colormap, e.g. 'Viridis' is fine,
                     * since 'interpolateViridis' exists
                     */
                    colormap: 'Viridis',
                    /** The number of steps to discretize the colormap with */
                    steps: 5,
                    /**
                     * The similarity range to use for filtering, and to normalize the colormap to
                     */
                    range: [0.65, 1.0],
                    /** The similarity mode of the displayed edges.*/
                    similaritymode: 'Subset',
                    
                },
                /**
                 * Lets the user toggle whether they want the SMARTS nodes to be colored by
                 * molecule matches, or by libraries (default). Only makes sense to be set to true
                 * when matchesLoaded is true as well, i.e., there are match result to render.
                 */
                showMatches: false,
                /** Sets the maximum DFS depth, 1 by default. */
                maxDFSDepth: 1,
                /** Sets the selection mode (hover or click), hover by default. */
                selectOn: 'hover',
                /** Enables/disables night mode. Disabled (false) by default. */
                nightMode: false,
                /** The similarity mode of the displayed edges.*/
                similaritymode: 'Subset',
                /** The molecule match data that can be downloaded */
                csvdata: [],
            },
            /**
             * The currently user-selected object (node or edge), which will be displayed
             * in the info box.
             */
            selectedObject: null,

            /** True if there is molecule match data available and loaded. */
            matchesLoaded: false,
            /**
             * Tracks the maximum number of molecule matches any single SMARTS achieved in the
             * last available match data.
             */
            maxMatches: null,
            /** The current matches */
            matches: [],
            
            
        };
    },
    /** Computed properties of the SMARTSexplore app. */
    computed: {
        /**
         * Whether to display molecule matches, based on ``matchesLoaded``
         * and ``settings.showMatches``.
         * @returns {boolean}
         */
        displayMatches() {
            return this.matchesLoaded && this.settings.showMatches;
        },
        /**
         * The current coloration function for the SMARTS nodes.
         * Switched based on ``displayMatches``:
         *
         * - If displayMatches is true, uses the number of matches relative to the maximum number
         *   of matches of all SMARTS and the library mapping to color each SMARTS node.
         * - If displayMatches is false, uses the mapping of libraries to colors to color each
         *   SMARTS node.
         * - In case the node is muted, assigns a color more similar to the background.
         */
        nodeColorFn() {
            if(this.displayMatches) {
                //const colorScale = d3.interpolateYlOrRd;

                return (node) => {
                    const colorScale = d3.scaleLinear().domain([1,10]).range(["#f3f3f3", this.libraryToColor[node.library]]);
                    const count = node.meta.matches.length;
                    if(!count || !this.maxMatches) {
                        return this.settings.nightMode ? '#333' : '#f3f3f3';
                    }
                    else if(node.meta.muted){
                        return colorScale(2);
                    }
                    else {
                        return colorScale(Math.round((Math.log(count)/Math.log(this.maxMatches))*6)+4);
                    }
                };
            }
            else {
                return (node) => {
                    if(node.meta.muted){
                    const colorScale = d3.scaleLinear().domain([1,10]).range(["#f3f3f3", this.libraryToColor[node.library]]);
                    return colorScale(2);
                    }
                    else{
                        return this.libraryToColor[node.library];
                    }
                }
            }
        },
        /**
         * The current color of the outlines of the nodes.
         * Switched based on ``displayMatches``:
         *
         * - If displayMatches is true, uses the number of matches relative to the maximum number
         *   of matches of all SMARTS to color each node outline.
         * - If displayMatches is false, returns black.
         */
        nodeStrokeOpacityFn(){
            if(this.displayMatches) {
               return (node) => {
                   const colorScale = d3.scaleLinear().domain([1,10]).range(["#dfdfe0", "black"]);
                   const count = node.meta.matches.length;
                   if(this.settings.nodeSelection.includes(node)){
                       return "#39ff14";
                   }
                   else if(!count || !this.maxMatches) {
                       return colorScale(5);
                   }
                   else{ 
                        return colorScale(Math.round((Math.log(count)/Math.log(this.maxMatches))*6)+4);
                   }
               }
           }
            else {
                return (node) => "black";
            }  
        },
        /**
         * The current visibility functions for the SMARTS nodes.
         * Nodes are visible if they are included in the library selection and the search regexp
         * matches their name (or is empty).
         */
        nodeVisibleFn_() {
            return (node) => this.settings.librarySelection[node.library]
                && matchesSearchRegexp(node, this.searchRegexp);
        },
        /**
         * The current CSS class function for the SMARTS nodes.
         * Sets 'hidden' if the node is not visible, 'muted' if it has been muted (by DFS actions or property selection), 'selected' if it is in the current node selection,
         * 'highlighted' if it is highlighted (by selection via hovering/clicking),
         * and 'transparent-border' if molecule matches are displayed and this node has none.
         */
        nodeClassFn() {
            return (node) => {
                return {
                    'hidden': !this.nodeVisibleFn_(node),
                    'muted': node.meta.muted,
                    'selected': this.settings.nodeSelection.includes(node),
                    'highlighted': node.meta.highlighted,
                    'transparent-border': this.displayMatches && !node.meta.matches.length
                };
            }
        },
        /**
         * The function determining if nodes are active (included in the simulation, DFS, ...).
         * Currently returns the exact same value as nodeVisibleFn.
         */
        nodeActiveFn() {
            return this.nodeVisibleFn_;
        },
        /**
         * The edge coloration function. Uses the current similarity->color mapping,
         * see the similarityToColor computed prop.
         */
        edgeColorFn() {
            return this.similarityToColor;
        },
        /**
         * The edge CSS style function. Determines the numerical edge width based on the similarity
         * value, using a quadratically proportional formula with upper and lower width bounds.
         * Equal edges are all displayed with the maximum thickness.
         */
        edgeStyleFn() {
            const scale = d3.scaleLinear()
                .domain(this.settings.edgeSimilarity.range).range([0, 1]);
            return (edge) => {
                const strokeWidth = (
                    edge.type === 'equal'
                    ? 10
                    : Math.min(Math.max(2.5, 8 * scale(edge.spsim)**2), 10)
                );
                return { strokeWidth };
            };
        },
        /**
         * The edge CSS class function. Mostly combines results for the nodes this edge connects.
         * If at least one node is hidden or muted, the edge is, too.
         * Apart from this, sets 'highlighted' if the edge is selected, and 'equal' if it is an
         * equal edge.
         */
        edgeClassFn() {
            const nodeVisibleFn = this.nodeVisibleFn_;
            return (edge) => {
                const { source, target } = edge;
                const muted = (source.meta.muted || target.meta.muted) || (
                    this.displayMatches && !(source.meta.matches.length && target.meta.matches.length)
                );

                return {
                    'hidden': !nodeVisibleFn(source) || !nodeVisibleFn(target),
                    'muted': muted,
                    'highlighted': edge.meta.highlighted,
                    'equal': edge.type === 'equal',
                    
                };
            }
        },
        /**
         * The function determining if an edge is active (included in the simulation, DFS, ...).
         * For the edge to be active, both connected nodes must be visible, and the edge must fall
         * within the chosen edge similarity range.
         */
        edgeActiveFn() {
            const nodeVisibleFn = this.nodeVisibleFn_;
            return (edge) => {
                const { source, target } = edge;
                const [min, max] = this.settings.edgeSimilarity.range;
                return nodeVisibleFn(source) && nodeVisibleFn(target) &&
                    min <= edge.spsim && edge.spsim <= max;
            }
        },
        /**
         * All SMARTS libraries stored in the graph, see ``getLibrariesFromGraph``.
         */
        graphLibraries() {
            return getLibrariesFromGraph(this.graph);
        },
        /**
         * Maps libraries to colors, see ``librariesToColorMapping``.
         */
        libraryToColor() {
            return librariesToColorMapping(this.graphLibraries);
        },
        /**
         * Maps edge similarity to a color value, based on the edgeSimilarity settings.
         * Configured by the number of quantization steps, the chosen color map
         * (all from d3-scale-chromatic are available), and the currently chosen similarity range
         * (for normalization).
         */
        similarityToColor() {
            const { steps, colormap, range, similaritymode } = this.settings.edgeSimilarity;
            let key = `interpolate${colormap}`;
            if(!colormap || !(key in d3)) key = 'interpolateViridis';

            const colors = d3.quantize(d3[key], steps || 2);
            const colorScale = d3.scaleQuantize().domain(range).range(colors);

            const colorFn = (edge) => {
                if(edge.type === 'equal') return this.settings.nightMode ? '#ffffff' : '#000000';
                else return colorScale(edge.spsim);
            }
            colorFn.colors = colors;
            return colorFn;
        },
    },
    methods: {
        /**
         * Initializes and returns a new Graph given nodes and edges, equipping the graph with
         * the required default meta information for nodes and for edges.
         */
        initGraph(nodes, edges) {
            const nodeMeta = { muted: false, highlighted: false, matches: [] }; 
            const edgeMeta = { highlighted: false };
            return new Graph(nodes, edges, nodeMeta, edgeMeta, true);
        },
        /**
         * Fetches the (initial) graph from the backend and replaces this.graph with it. Adjusts the current 
         * edges and the visibility of arrowheads according to the similarity mode.
         * Shows a Materialize toast if anything goes wrong.
         */
        async fetchGraph() {

            const session_storage_id = window.sessionStorage.getItem("current");
            
            const request = fetch('/smarts/data', {
                method: 'post',
                body: JSON.stringify({ 'spsim_min': 0, 'spsim_max': 1,'session_storage_id': session_storage_id }),
                headers: { 'Content-Type': 'application/json' }
            });

            try {
                const response = await request;
                const json = await response.json();
                const ok = response.ok;

                if(ok) {
                    if (this.settings.similaritymode == "Subset"){
                        const graph = this.initGraph(json.nodes, json.edges);
                        this.graph = graph;
                        const markers= document.querySelectorAll(".visiblemarkers");
                        markers.forEach(marker => {
                        marker.style.visibility = 'visible';
                        });
                    }
                    else if (this.settings.similaritymode == "Similarity"){
                        const graph = this.initGraph(json.nodes, json.undirectededges);
                        this.graph = graph;
                        const markers= document.querySelectorAll(".visiblemarkers");
                        markers.forEach(marker => {
                        marker.style.visibility = 'hidden';
                        });
                    };
                    
                }
                else {
                    throw new Error(json.error || String(response.status));
                }
            } catch(e) {
                console.error(e);
                M.toast({
                    html: `
                        Could not fetch graph data. Please retry later or contact an administrator.
                        Error: ${e}
                    `,
                    displayLength: Math.inf
                });
            }

        },
        /**
         * Handles users hovering over an edge: if selectOn == 'hover', sets the selected object
         * to be the hovered edge.
         */
        handleEdgeHover({ edge, event }) {
            if(this.settings.selectOn == 'hover'){
                this.selectedObject = edge;
                
            }
        },
        /**
         * Handles users clicking an edge: if selectOn == 'click', sets the selected object
         * to be the clicked edge.
         */
        handleEdgeClick({ edge, event }) {
            if(this.settings.selectOn == 'click'){
                this.selectedObject = edge;
            }
        },
        /**
         * Handles users hovering over a node:
         *
         *   * if selectOn == 'hover', sets the selected object to be the hovered node.
         *   * if mode is set to 'Subset' and alt (incoming) or ctrl (outgoing) are held while hovering, runs a *   * DFS in the given direction and mutes all unreached nodes.
         *   * if both alt and ctrl are held, runs an undirected DFS and mutes all unreached nodes.
         *   * if mode is set to 'Similarity', both key presses result running an undirected DFS and muting all *   * unreached nodes.
         */
        handleNodeHover({ node, event }) {


            if(event.altKey || event.ctrlKey) {
                const maxDepth = this.settings.maxDFSDepth;
                let dir = 'all';
                if(event.ctrlKey && !event.altKey) dir = 'outgoing';
                else if(event.altKey && !event.ctrlKey) dir = 'incoming';
                if(this.settings.similaritymode == "Similarity") dir = 'all';
                

                // Mute all nodes, unmute only reached nodes

                _.each(this.graph.nodes, (node) => { node.meta.muted = true; })
                this.graph.runDFS(
                    node, dir, maxDepth,
                    (node) => { node.meta.muted = false; },
                    null, null, this.edgeActiveFn, null
                );
                
            }

            if(this.settings.selectOn  == 'hover'){
                this.selectedObject = node;
            }
        },
        /**
         * Handles users clicking a node: if selectOn == 'click', sets the selected object
         * to be the clicked node. Adds node to the node selection.
         */
        handleNodeClick({ node, event }) {
            var duplicate = false;
            var index = -1;
            _.each(this.settings.nodeSelection, (selectednode) => {if(selectednode.id == node.id){
             duplicate = true;   
             index = (this.settings.nodeSelection).indexOf(selectednode);
            }
            })
            if(duplicate == false)
            {
            (this.settings.nodeSelection).push(node);
            }
            else{
                (this.settings.nodeSelection).splice(index, 1);   
            };
            if(this.settings.selectOn == 'click') {
                this.selectedObject = node;
            }
        },
        /**
         * Handles users clicking the background: If ctrl or alt are held, unmutes all nodes and checks all properties
         * (reverting the effects of alt/ctrl node hover).
         */
        handleGraphBackgroundClick(event) {
            if(event.altKey || event.ctrlKey) {
                // unmute all nodes
                let arr = document.querySelectorAll('[id^="__property-checkbox-"]');
                _.each(this.graph.nodes, (node) => { node.meta.muted = false; });
                _.each(arr, (box) => { box.checked = true; });
            }
            
        },
        /**
         * Handles a successful response of the backend to a molecule upload request.
         * Stores match data and sets the application up to show it unless it is just being updated after a SMARTS upload. Generates csv molecule match data that can be downloaded.
         */
        handleFileUploadResponse(response) {
            
            _.each(document.querySelectorAll('[id^="__property-checkbox-"]'), (box) => { box.checked = true;  });
            _.each(this.graph.nodes, (node) => { node.meta.muted = false; });
            this.selectedObject=null;
            var csv=[];
            var { matches } = response;
            if(response == "update"){
                matches = this.matches;
                
            }
            else{
            this.matches = matches;
            }
            const matchesPerSMARTS = {};
            var matchesOfLibs = {};
            
            document.getElementById("smartsuploadbox").disabled=false;

            _.each(this.graph.nodes, (node) => {
                node.meta.matches.length = 0;  // prune existing data
            });
            _.each(matches, (match) => {
                if(this.graph.getNodeById(match.smarts_id))
                {   const currentnode = this.graph.getNodeById(match.smarts_id);
                    const annotation = currentnode.annotation;
                    
                    if(currentnode.library in matchesOfLibs && !(matchesOfLibs[currentnode.library].includes(currentnode))){
                        matchesOfLibs[currentnode.library].push(currentnode);
                    }
                    else if(!(currentnode.library in matchesOfLibs)){
                        matchesOfLibs[currentnode.library] = [currentnode];
                    }
                    
                    
                    
                    if(response != "update"){
                    if(match.molecule_id in csv){
                        csv[match.molecule_id][3].push([currentnode.pattern,currentnode.name,annotation]);
                        
                    
                    }
                    else{
                        csv[match.molecule_id]=[0,match.molecule_pattern, match.molecule_name, [[currentnode.pattern,currentnode.name,annotation]]];
                    }
                    }
                
                    const prevMatches = matchesPerSMARTS[match.smarts_id];
                    matchesPerSMARTS[match.smarts_id] = prevMatches ? prevMatches + 1 : 1;
                    currentnode.meta.matches.push(match);
                }
            });
            _.each(this.graph.nodes, (node) => {
                node.meta.matches = _.sortBy(node.meta.matches, 'molecule_id');
            });
            this.maxMatches = _.max(Object.values(matchesPerSMARTS));
            if(response != "update"){
                this.settings.csvdata = csv;
                this.matchesLoaded = true;
                this.settings.showMatches = true;  // user probably will want to see results right away
                
            };

                               
                   
        },
        /**
         * Handles a successful response of the backend to a SMARTS upload request.
         * Fetches the graph again to show the new data and restores node highlights.
         * If molecule matches are loaded, updates them to include matches for the newly uploaded SMARTS.
         */
        async handleSMARTSFileUploadResponse(response){
            
            document.getElementById("uploadbox").disabled=false;
            if(this.matchesLoaded){
                        const displayed = this.settings.showMatches;
                        const data = new FormData();
                        data.append("sessionStorageId", window.sessionStorage.getItem("current"));
                        const request = new Request("/molecules/update", {
                                method: 'post',
                                body: data
                        })
                try {
                        const response = await fetch(request);
                        const json = await response.json();
                        const ok = response.ok;
                        M.toast({ html: 'SMARTS set upload finished!' });
                        await this.fetchGraph();
                        this.handleFileUploadResponse(json);
                        if(displayed==false){
                            this.settings.showMatches = false;
                        }
                    } catch(err) {
                        let msg = (err instanceof Error) ? err.message : err;
                        if(!msg) msg = "Unknown reason"
                        M.toast({ html: `Couldn't update molecule smatches: ${msg}` });
                    } 
            }
            else{
            await this.fetchGraph();
                }

            this.afterFetchGraphSelection(this.settings.nodeSelection);


        },
            /**
            * Handles a successful response to a similarity change.
            * Fetches the graph anew to show the desired edges and restores node highlights.
            */
            async handleSimilarityChangeResponse(response){
                this.settings.similaritymode=response;
                await this.fetchGraph();
                if(this.matchesLoaded){
                this.handleFileUploadResponse("update");
                };
                    
                this.afterFetchGraphSelection(this.settings.nodeSelection);
        },
        /**
        * Restores node selection highlights.
        */
        afterFetchGraphSelection(selectedNodes){
                //this.selectedObject = this.graph.getNodeById(selected.id); 
                var nodeSelectionAfter = [];
                _.each(selectedNodes, (node) => { nodeSelectionAfter.push(this.graph.getNodeById(node.id))});
                this.settings.nodeSelection = nodeSelectionAfter;    
                this.selectedObject = null;
        },
        /**
        * Updates the selected object.
        */
        updateSelectedObject(object){
            this.selectedObject = object;
        },
        /**
        * Updates the node selection by adding nodes selected with the drag selection box.
        */
        updateNodeSelection(boxSelectedNodes){
            _.each(boxSelectedNodes, (node) => {if(!this.settings.nodeSelection.includes(node)){this.settings.nodeSelection.push(node)}});
            
        },
        /**
        * Sends an HTTP request to the backend to delete all data associated with the current session. Shows a Materialize toast if anything goes wrong.
        */
        async requestDeleteUpload(){
            const session_storage_id = window.sessionStorage.getItem("current");
                
            const request = fetch('/smarts/deleteupload', {
                    method: 'post',
                    body: JSON.stringify({ 'session_storage_id': session_storage_id }),
                    headers: { 'Content-Type': 'application/json' }
                });

                try {
                        const response = await request;
                        const json = await response.json();
                        const ok = response.ok;
                    } catch(err) {
                        let msg = (err instanceof Error) ? err.message : err;
                        if(!msg) msg = "Unknown reason"
                        M.toast({ html: `Couldn't delete user uploaded SMARTS: ${msg}` });
                    }             
        },
    },
    /**
     * Watchers of the SMARTSexplore app
     */
    watch: {
        /**
         * Watches 'selectedObject' and updates the 'highlighted' meta attribute on both the
         * previously selected and the newly selected object.
         */
        selectedObject(newVal, oldVal) {
            // this is a Vue-specific optimization: We could check for each object if it is
            // highlighted by comparing against this.selectedObject, but this will add a render
            // update dependency on this.selectedObject for *every such object*, i.e., changing
            // this.selectedObject would trigger an update on *every edge and node*.
            //
            // Instead, we keep the update dependency one-sided by watching selectedObject and
            // only applying a change on the old & new selectedObject's meta information.
            if(newVal) newVal.meta.highlighted = true;
            if(oldVal && oldVal !== newVal) oldVal.meta.highlighted = false;
        },
        /**
         * Watches 'graphLibraries' and keeps the library selection up to date, by dropping removed
         * libraries and adding new libraries with a default 'true' value for the selection
         */
        graphLibraries(newVal, oldVal) {
            // If the list of graph libraries changes, update the library selection accordingly:
            const sel = this.settings.librarySelection;
            //  - if new libraries were added, set their selection to 'true'
            _.each(newVal, (library) => {
                sel[library] = library in sel ? sel[library] : true;
            });
            //  - if libraries were removed, remove them from the selection dict
            _.each(Object.keys(sel), (library) => {
                if(_.indexOf(newVal, library) === -1) {
                    delete sel[library];
                }
            });
        },
        /**
         * Watches 'searchString' to parse it as a regular expression, updates 'searchRegexp' if
         * parsing is successful, and sets an error flag if parsing fails.
         */
        'settings.searchString': function(newVal, oldVal) {
            try {
                const regexp = new RegExp(newVal, 'i');
                this.settings._searchStringError = false;
                this.searchRegexp = regexp;
            } catch(e) {
                this.settings._searchStringError = true;
            }
        }
    },
    created(){
        /**
         * Adds an event listener to delete the SMARTS, matches, molecules and edges unique to this session when the 
         * window or tab is closed or reloaded.
         */
        
      window.addEventListener("beforeunload", function (event) {
            const session_storage_id = window.sessionStorage.getItem("current");
            const request = new XMLHttpRequest();
            request.open('POST', '/smarts/deleteupload', false);  // `false` makes the request synchronous
            request.setRequestHeader("content-type", "application/json");
            request.send(JSON.stringify({ 'session_storage_id': session_storage_id }));
          
            
            
    })
    },
    /**
     * The uploaded smarts file is set to an empty string
     */
    mounted(){
        if(this.$refs.doc){
            
           this.$refs.doc.value = '';
        }
    },
    /**
     * Before mounting this component, starts to fetch the initial graph from the backend. If the last session storage has not been properly deleted (primarily olny a problem in Opera) delete the remaining user uploaded data. Initiates the default checked states for the similarity 
     * mode selection checkboxes.
     */
    async beforeMount() {
    
        if(window.sessionStorage.getItem("current")){
            await this.requestDeleteUpload();
        }
        window.sessionStorage.setItem("current", uuidv4());

        this.fetchGraph();

        document.getElementById('sub').checked=true;
        document.getElementById('sim').checked=false;
    }
};

////////////////////// DEFINE THE APP AND ITS COMPONENTS ///////////////////////

const app = Vue.createApp(appDescription);


////////// SETTINGS CONTAINER //////////

app.component('collapsible', {
    // TODO
});

    /**
     * The component for the settings container.
     */
app.component('settings', {
    name: 'settings',
    components: { RangeSlider, NodeFix, UploadBox, SMARTSUploadBox, InfoButton, SimilarityInfoButton, AboutButton, ControlsButton, SimilarityMode },
    emits: ['update:modelValue', 'fileUploadResponse', 'SMARTSfileUploadResponse', 'similarityChangeResponse', 'updateSelectedObject'],
    props: {
        modelValue: Object,
        libraryToColor: Object,
        colorMap: {
            type: Function,
            validator: (fn) => fn.length == 1 && fn.colors instanceof Array
        },
        matchesLoaded: Boolean,
        selectedObject: Object,
        graph: Object,
        edgeActiveFn: Boolean,
    },
    data() {
        return {
            moleculeSetUploadUrl: '/molecules/upload',
            SMARTSSetUploadUrl: '/smarts/upload',
            previousSelectedObject: null,
            selectionMode: 'libraryMode',
            propertySelection: {},
        };
    },
    computed: {
        s: {
            get() {
                return this.modelValue;
            },
            set(value) {
                this.$emit('update:modelValue', value);
            }
        },
        colorbarBlockWidth() {
            return (1 / this.colorMap.colors.length) * 100 + '%';
        },
        libraryIDs() {
            let result = Object.keys(this.s.librarySelection);
            return result;
        },
        /**
         * Returns all properties.
         */
        properties(){
            let result = Object.keys(this.propertySelection);
            return result;            
        },
        
    },
    watch:{
      /**
      * Watches 'graph' and updates 'propertySelection' to keep the properties up to date with uploaded SMARTS data.
      */
      graph: function(newVal,oldVal){
            var propertyDict = {};
            const propertyArray = getPropertiesFromGraph(this.graph);
            _.each(propertyArray, (property) => { if(property in propertyDict==false){ propertyDict[property]=true;}});

            this.propertySelection=propertyDict;

      },

      
    },
    mounted() {
        this._collapsible = M.Collapsible.init(this.$refs.collapsible, {
            accordion: false,
        });
    },
    methods: {
        /**
         * Add all libraries to the library selection.
         */
        selectAllLibraries() {
            Object.keys(this.s.librarySelection).forEach((k) => {
                this.s.librarySelection[k] = true;
            });
            

        },
        
        /**
         * Delete all libraries from the library selection.
         */
        deselectAllLibraries() {
            Object.keys(this.s.librarySelection).forEach((k) => {
                this.s.librarySelection[k] = false;
            });
        },
        /**
         * Adjusts edge similarity slider minimum according to similarity mode.
         */
        getslidermin(){
            const firstmarker = document.querySelector(".visiblemarkers");
            if (firstmarker){
            return (firstmarker.style.visibility=="hidden" ? 0.5 : 0.0); 
                
            }
            else{
                return 0.0;
                
            }
        },
        /**
         * While hovering over a node in the node selection box, the current node is selected and thereby highlighted. Saves the previously selected object if there is one.
         */
        highlightSelectedNode(node){
            if(this.graph.getNodeById(node.id))
            {   if(this.selectedObject){
                this.previousSelectedObject = this.selectedObject;
                this.$emit('updateSelectedObject', this.graph.getNodeById(node.id));//this.selectedObject = node;
                }
                else{
                    this.$emit('updateSelectedObject', this.graph.getNodeById(node.id));
                }
            };
            
                

        },
        /**
         * Undo highlight of node. Redo selection of previously selected object, if available, to highlight it again.
         */
        undoHighlightSelectedNode(node){
            if(this.previousSelectedObject){
                this.$emit('updateSelectedObject', this.previousSelectedObject);
            }
            else{
                this.$emit('updateSelectedObject', null);
            };
        },
        /**
         * Adds all nodes to the node selection.
         */
        selectAllNodes(){
            this.s.nodeSelection=this.graph.nodes;
        },
        /**
         * Deletes all nodes from the node selection.
         */
        deselectAllNodes(){
            this.s.nodeSelection=[];
        },
        /**
         * Adds all properties to property selection and adjusts node visibility.
         */
        selectAllProperties(){
            Object.keys(this.propertySelection).forEach((k) => {
                this.propertySelection[k] = true;
            });
            _.each(this.graph.nodes, (node) => { node.meta.muted = false; })
        },
        /**
         * Deletes all properties from property selection and adjusts node visibility.
         */
        deselectAllProperties(){
            Object.keys(this.propertySelection).forEach((k) => {
                this.propertySelection[k] = false;
            });
            _.each(this.graph.nodes, (node) => { if(!this.s.showMatches || node.meta.matches.length > 0){node.meta.muted = true;} })
        },
        /**
         * Handles checking or unchecking of property checkbox by adjusting visibility of nodes.
         */
        selectProperty(property){
            if(this.propertySelection[property]==false){
                if(property=="No annotation"){
                    _.each(this.graph.nodes, (node) => { if(node.annotation==""){ node.meta.muted = false; }})
                }
                else{
                _.each(this.graph.nodes, (node) => { if(node.annotation.split(" ").includes(property)){ node.meta.muted = false; }})
                }
                
            }
            else{
                if(property=="No annotation"){
                    _.each(this.graph.nodes, (node) => { if(node.annotation==""){ if(!this.s.showMatches || node.meta.matches.length > 0) node.meta.muted = true; }})
                }
                else{
                    _.each(this.graph.nodes, (node) => { if(node.annotation.split(" ").includes(property)){if(!this.s.showMatches || node.meta.matches.length > 0){
                    node.meta.muted = true; 
                    Object.keys(this.propertySelection).forEach((k) => {
                        if(k != property && this.propertySelection[k] == true && node.annotation.split(" ").includes(k)){
                                node.meta.muted = false;
                        }
                        
                    });
                        
                    }}})
                }
            }
            
        },
        /**
         * Delete node from node selection. Highlight previous selected object again if there is one. 
         */
        deselectNode(index){
            if(this.previousSelectedObject){
                this.$emit('updateSelectedObject', this.previousSelectedObject);
            }
            else{
                this.$emit('updateSelectedObject', null);
            };
            this.s.nodeSelection.splice(index,1);
            
        },
        updateFilename(filename){
            document.getElementById("displayedfilename").innerHTML=" "+filename;
        },
        /**
         * Disable SMARTS Upload. 
         */
        disableSUpload(disable){
            document.getElementById("smartsuploadbox").disabled=disable;
        },
        /**
         * Disable Molecule Upload. 
         */
        disableMUpload(disable){
            document.getElementById("uploadbox").disabled=disable;
        },
        /**
         * Download .smarts-file containing the current SMARTS selection.
         */
        downloadSelected(){
            
            var text = ""
            this.s.nodeSelection.forEach((node) => {
                text += node.pattern + " " + node.name + " " +node.annotation+ "\r\n"
            });
            var element = document.createElement('a');
            element.setAttribute('href', 'data:text/plain;charset=utf-8,' + encodeURIComponent(text));
            element.setAttribute('download', "SMARTSexplore.smarts");

            element.style.display = 'none';
            document.body.appendChild(element);

            element.click();

            document.body.removeChild(element);    
        },
        /**
         * Download .csv-file containing the uploaded smiles sorted by number of matches annotated with their matched SMARTS.
         */
        downloadMoleculeMatches(){
            var option = document.getElementById("download-options").selectedIndex;
            var array = [];
            var sel = this.s.librarySelection;
            var selectednodenames = [];
            var selectednodepatterns = [];
            this.s.nodeSelection.forEach(function(node){
                        selectednodenames.push(node.name);
                        selectednodepatterns.push(node.pattern);
                        
                    });
            for (var k in this.s.csvdata) {
                if(option == 0){

                    var items = [];
                    this.s.csvdata[k][3].forEach(function(item, index, object) {
                        if (sel[item[1].split('.')[0]]==true){
                            items.push(item);
                            
                        }
                    }
                                         );
                    
                    if(items.length != 0){
                        array.push([items.length].concat(this.s.csvdata[k][1],this.s.csvdata[k][2],[items]));
                    }
                }
                else if(option == 1){
                    var items = [];
                    this.s.csvdata[k][3].forEach(function(item, index, object) {
                        if (selectednodenames.includes(item[1]) && selectednodepatterns.includes(item[0])){
                            items.push(item);
                            
                        }
                    }
                                         );
                    if(items.length != 0){
                        array.push([items.length].concat(this.s.csvdata[k][1],this.s.csvdata[k][2],[items]));
                    }
                    
                    
                }
                else if(option == 2){

                    var items = [];
                    this.s.csvdata[k][3].forEach(function(item, index, object) {
                        items.push(item);
                    }
                                         );
                    
                    if(items.length != 0){
                        array.push([items.length].concat(this.s.csvdata[k][1],this.s.csvdata[k][2],[items]));
                    }

                }
                };

            array.sort(function(first, second) {
                return second[0] - first[0];
                });
            var maxlen= Math.max(...(array.map(el => el[3].length +3)));
            
            var csv = 'Number of Matches\tSMILES\tName';
            let x=1;
            while (x<= maxlen-3){
                csv+='\t'+'Match '+x;
                x+=1;
            };
            csv+="\n";
            array.forEach(function(row) {
                    row[3].forEach(function(item, index, object){
                        object[index]=item.join(" ");
                    }
                           );
                    row=row.flat(1);
                    csv += row.join('\t');
                    csv += "\n";
            });

            
            var element = document.createElement('a');
            element.setAttribute('href', 'data:text/csv;charset=utf-8,' + encodeURIComponent(csv));
            element.setAttribute('download', "MoleculeMatches.csv");

            element.style.display = 'none';
            document.body.appendChild(element);

            element.click();

            document.body.removeChild(element); 

        },
        /**
         * Returns ID of current storage session.
         */
        getSessionStorageId(){
            return window.sessionStorage.getItem('current');
        },
    },
    template: `
<div class="settings-container">
    <ul ref="collapsible" class="collapsible">
        <li class="active">
            <div class="collapsible-header">
                <i class="material-icons">info_outline</i>Info
            </div>
            <div class="collapsible-body">
                <div class="row info">
                    <label>SMARTSexplore is a network analysis tool [...]</label>
                    <div class="row info button" style="display: inline-flex; justify-content: space-between;">
                        <about-button />
                        <div style=" padding-left:10px; float:right; display:inline-block;">
                        <controls-button />
                        </div>
                    </div>
                </div>
            </div>
        </li>
        <li class="active">
            <div class="collapsible-header">
                <i class="material-icons">my_location</i>Focus
            </div>
            <div class="collapsible-body">
                <div class="row focus">
                    <div class="object-selection col s12">
                        <nodeFix v-model="s.selectOn"/>
                    </div>
                </div>
                <div class="row dfs-settings">
                    <div class="col s6">
                        <label>
                            DFS depth
                            <input type="number" v-model.number="s.maxDFSDepth" min="1" max="10" step="1" />
                        </label>
                    </div>
                    <div class="col s6">
                        <div><label>Night mode</label></div>
                        <label class="input-like">
                            <input type="checkbox" v-model.bool="s.nightMode" />
                            <span>{{ s.nightMode ? 'En' : 'Dis' }}abled</span>
                        </label>
                    </div>
                </div>
            </div>
        </li>
        <li class="active">
            <div class="collapsible-header" id="node-selection-header">
                <i class="material-icons">adjust</i>Node selection
            </div>
            <div class="collapsible-body">
                <div class="row search">
                    <div class="search-container input-field col s12">
                        <i class="material-icons prefix">search</i>
                        <input id="searchbar" :class="{'invalid': s._searchStringError}"
                            v-model.lazy="s.searchString" type="text" />
                        <label for="searchbar">Search nodes by name</label>
                    </div>
                </div>

                <div class="row library-select">
                    <div class="col s12" v-if="selectionMode=='libraryMode'">
                        <label>
                            Library selection:
                            <a href="#" @click="selectAllLibraries">All</a> |
                            <a href="#" @click="deselectAllLibraries">None</a>
                        </label>

                        <div class="row" id ="library-names-and-boxes">
                            <!-- FIXME horribly hacky, but what else should we do with this MaterializeCSS selector? -->
                            <div class="col s12 l6 library-selector" v-for="libraryID in libraryIDs">
                                <component is="style">
                                    [type=checkbox]#__library-checkbox-{{ libraryID }}:checked + span::after {
                                        background-color: {{ libraryToColor[libraryID] }};
                                        border-color: transparent;
                                    }
                                </component>
                                <label>
                                    <input type="checkbox" class="filled-in" v-model="s.librarySelection[libraryID]"
                                        :id="'__library-checkbox-' + libraryID" />
                                    <span>{{ libraryID }}</span>
                                </label>
                            </div>
                        </div>
                    </div>
                        <div class="row info-button">
                            <div class="col s12">
                                <info-button/>
                            </div>
                        </div>
                    
                    
                <div class="col s12" >
                        <label style="margin-bottom:0;">
                            Click to select, hold Shift to drag-select <br>
                            Node Selection:
                            <a href="#" @click="selectAllNodes">All</a> |
                            <a href="#" @click="deselectAllNodes">None</a>
                        </label>
                        <div style="padding-bottom:2px;">
                        <div id="overflow-node-selection" class="overflow-node-selection">
                        <li v-for="(node, index) in this.s.nodeSelection" @mouseenter="highlightSelectedNode(node)" @mouseleave="undoHighlightSelectedNode(node)">
                        <a @click="deselectNode(index)" style="color:#9e9e9e;">&#x2715 </a>
                        <a style="color:#9e9e9e;"> {{node.name}} {{node.pattern}} {{node.annotation}}</a>
                        </li>
                        </div>
                        </div>
                </div>
                <div class="col s12">
                <div class="form-group1">
                <input id="smarts-download" type="button" @click="downloadSelected()" value="Download selected SMARTS" style="height:25px; width:100%; text-align: center; vertical-align: middle;" />
                </div>
                </div>
                </div>
            </div>
        </li>
        <li class="active">
            <div class="collapsible-header">
                <i class="material-icons">trending_flat</i>Edge selection
            </div>
            <div class="collapsible-body">
                <div class="row">
                    <div class="col s12" >
                        <label style="vertical-align: middle;" > Similarity mode</label> 
                    </div>
                    <SimilarityMode @response="$emit('similarityChangeResponse', $event)"/>
                    <div class="col s12">
                        <SimilarityInfoButton/>
                    </div>
                    <div class="col s12">
                        <rangeSlider :min="getslidermin()" :max="1" :step="0.01"
                                    :startMin="s.edgeSimilarity.range[0]" :startMax="s.edgeSimilarity.range[1]"
                                    v-model="s.edgeSimilarity.range" />
                    </div>
                </div>
                <div class="row">
                    <div class="col s8">
                        <label class="display-block">Colorscale of current range</label>
                        <div class="colorbar">
                            <span class="colorbar-block" v-for="color in colorMap.colors"
                                :style="{ background: color, width: colorbarBlockWidth }">
                            </span>
                        </div>
                    </div>
                    <div class="col s4">
                        <label>
                            Steps
                            <input type="number" v-model.number="s.edgeSimilarity.steps" min="2" max="10"
                                step="1" />
                        </label>
                    </div>
                </div>
            </div>
        </li>
        
        
        
        <li class="active">
            <div class="collapsible-header">
                <i class="material-icons">list</i>Properties
            </div>
            <div class="collapsible-body">
                <div class="row property-box">
                    <div class="col s12">
                        <label>
                            Property highlight:
                            <a href="#" @click="selectAllProperties">All</a> |
                            <a href="#" @click="deselectAllProperties">None</a>
                        </label>

                        <div class="row">
                            
                            <div class="col s12  property-selector" v-for="property in properties">
                                <label>
                                    <input type="checkbox" @click="selectProperty(property)" class="filled-in" v-model="propertySelection[property]"
                                        :id="'__property-checkbox-' + property" />
                                    <span>{{ property }}</span>
                                </label>
                            </div>
                        </div>
                    </div>   
                </div>
            </div>
        </li>
        
        
        
        
        <li class="active">
            <div class="collapsible-header">
                <i class="material-icons">file_upload</i>Molecule Upload
            </div>
            <div class="collapsible-body">
                <div class="row upload-box">
                    <div class="col s12">
                        <UploadBox :target-url="moleculeSetUploadUrl"  :sessionStorageId=getSessionStorageId() @updateFilename="updateFilename" @disableSUpload="disableSUpload"  @response="$emit('fileUploadResponse', $event)" />
                        <div style="padding-top:2px;">
                        <label v-if="matchesLoaded" for="download-options">Download Molecule Matches for:</label>
                        <select style="height: 2rem; display:block;" v-if="matchesLoaded" name="download-options" id="download-options">
                            <option value="libraries" selected="selected">Selected libraries</option>
                            <option value="nodes">Selected nodes</option>
                            <option value="all">All nodes</option>
                        </select>
                        
                        <input type="button" v-if="matchesLoaded" @click="downloadMoleculeMatches()" value="Download Molecule Matches" id="matches-download" style="height:25px; width:100%; text-align: center; vertical-align: middle;" />
                        </div>
                    </div>
                    <div class="col s12 matches-toggle" v-if="matchesLoaded">
                        <label>
                            <input v-model="s.showMatches" type="checkbox" class="filled-in">
                            <span>Show molecule matches</span>
                        </label>
                    </div>
                </div>
            </div>
        </li>
        <li class="active">
            <div class="collapsible-header">
                <i class="material-icons">file_upload</i>SMARTS Upload
            </div>
            <div class="collapsible-body">
                <div class="row smarts-upload-box">
                    <div class="col s12">
                        <SMARTSUploadBox :target-url="SMARTSSetUploadUrl" :sessionStorageId=getSessionStorageId() @disableMUpload="disableMUpload" @response="$emit('SMARTSfileUploadResponse', $event)"/>
                    </div>
                </div>
            </div>
        </li>
    </ul>
</div>
`,
});


export { app };
