import * as M from 'materialize-css/dist/js/materialize.min.js'

const Modal = {
    name: 'Modal',
    props: {
        id: String,
        buttonText: String,
    },
    template:`
    <!--Modal Trigger-->
    <a class="waves-effect waves-light btn modal-trigger" :href="'#' + id">{{ buttonText }}</a>

    <!-- Modal Structure -->
    <div :id="id" class="modal" ref="modal">
        <div class="modal-content">
            <slot></slot>
        </div>
        <div class="modal-footer">
            <a href="#!" class="modal-close waves-effect waves-green btn-flat">Close</a>
        </div>
    </div>
`,
    mounted() {
        this._modal = M.Modal.init(this.$refs.modal);
    },
    beforeUnmount() {
        this._modal.destroy();
    }
};



/**
 * The about button of the app, containing a modal that explains the application to the user.
 */
const AboutButton = {
    name: 'AboutButton',
    components: { Modal },
    template: `
    <modal id="about-modal" button-text="Learn more">
        <h4>About SMARTSexplore</h4>
        <p>
            SMARTSexplore is a web-based chemical pattern network analysis tool that allows
            comparison of available SMARTS-libraries and allows uploading of .smiles-files for
            matching with SMARTS.
        </p>
        <p>This tool was created by Inken Fender, Pia Pluemer and Simon Welker, 2021.</p>
        <p>SMARTSexplore is part of the SMARTSplus web services which can be found
        <a href="https://smarts.plus">here</a>.</p>
    </modal>
`,
};

/*<li class="collection-item">
            The SMARTSexplore graph shows SMARTS from various libraries and their similarities represented by different edges. Edges that are not contained in the similarity range are not shown. Edges shown have their similarity represented with a color scale where black edges depict identical SMARTS. Nodes are colored according to their libraries. When molecule matches are shown, opacity indicates the number of matches.
            </li>*/
/**
 * The controls button of the app, containing a modal that explains the graph controls and provides a link for further information to the user.
 */
const ControlsButton = {
    name: 'ControlsButton',
    components: { Modal },
    template: `
    <modal id="control-modal" button-text="Controls">
        <h4>Controls</h4>
        <ul class="collection">
            <li class="collection-item">
            Dragging nodes:<br>
            <span style="color:#26a69a">Click</span> and <span style="color:#26a69a">drag</span> a node to bring it to the desired location. 
            </li>
            <li class="collection-item">
            Zooming:<br>

            <span style="color:#26a69a">Scroll</span> to zoom in or out.
            </li>
            <li class="collection-item">
            Hovering:<br>

            <span style="color:#26a69a">Hover</span> over nodes and edges to get more information. 
            You can change this setting to only show information on click in the Focus section.
            </li>
            <li class="collection-item">
            Panning:<br>

            <span style="color:#26a69a">Click</span> and <span style="color:#26a69a">drag</span> the background of the graph to pull the image to the desired location.
            </li>

            <li class="collection-item">
            Selection:<br>

            <span style="color:#26a69a">Click</span> on a node to add it to the Node Selection. 
            </li>
            <li class="collection-item">    
            Drag-selection:<br>
            
            Hold down <span style="color:#26a69a">Shift</span>, then <span style="color:#26a69a">click</span> on the background and <span style="color:#26a69a">drag</span> to add multiple nodes to the Node Selection via the selection box.
            </li>
            <li class="collection-item">
            Depth-first search exploration:<br>
            <span style="color:#26a69a">Hover</span> over a node while holding down <span style="color:#26a69a">alt</span>/<span style="color:#26a69a">ctrl</span> to show incoming/outgoing edges with connected nodes. Hold <span style="color:#26a69a">alt</span>+<span style="color:#26a69a">ctrl</span> for both types of edges. Change the depth of the search algorithm in the Focus section. In Similarity mode either key shows the undirected connected edges and nodes.
            
            </li>

        </ul>
        <button href="#" onclick='window.open("guide");return false;' class="waves-effect waves-light btn modal-trigger">
        Detailed User Guide
        </button>
    </modal>
`,
};


/**
 * The library info button of the app, containing references to the included SMARTS libraries.
 */
const InfoButton = {
    name: 'InfoButton',
    components: { Modal },
    template: `
    <modal id="info-modal" button-text="Info">
        <h4>References</h4>
        <ul class="collection">
            <li class="collection-item">
            AlphaScreen-GST-FHs: Brenke, J. K.; Salmina, E. S.; Ringelstetter, L.; Dornauer, S.; Kuzikov, M.; Rothenaiger, I.; Schorpp, K.; Giehler, F.; Gopalakrishnan, J.; Kieser, A.; Gul, S.; Tetko, I. V.; Hadian, K. Identification of Small-Molecule Frequent Hitters of Glutathione S-Transferase-Glutathione Interaction. J Biomol Screen. 2016 Jul;21(6):596-607., <a href="https://doi.org/10.1177/1087057116639992" target="_blank">https://doi.org/10.1177/1087057116639992</a></li>
            <li class="collection-item">AlphaScreen-FHs and AlphaScreen-His-FHs: Schorpp, K.; Rothenaigner, I.; Salmina, E.; Reinshagen, J.; Low, T.; Brenke, J. K.; Gopalakrishnan, J.; Tetko, I. V.; Gul, S.; Hadian, K. Identification of Small-Molecule Frequent Hitters from AlphaScreen High-Throughput Screens. J Biomol Screen. 2014 Jun;19(5):715-26., <a href="https://doi.org/10.1177/1087057113516861" target="_blank">https://doi.org/10.1177/1087057113516861</a></li>
            <li class="collection-item">BMS (Bristol-Myers Squibb): Pearce, B. C.; Sofia, M. J.; Good, A. C.; Drexler, D. M.; Stock, D. A. An empirical process for the design of high-throughput screening deck filters. J. Chem. Inf. Model. 2006, 46, 1060−1068., <a href="https://doi.org/10.1021/ci050504m" target="_blank">https://doi.org/10.1021/ci050504m/de</a></li>
            <li class="collection-item">Dundee: Brenk, R.; Schipani, A.; James, D.; Krasowski, A.; Gilbert, I. H.; Frearson, J.; Wyatt, P. G. Lessons learnt from assembling screening libraries for drug discovery for neglected diseases. ChemMedChem 2008, 3, 435−444., <a href="https://doi.org/10.1002/cmdc.200700139" target="_blank">https://doi.org/10.1002/cmdc.200700139</a></li>
            <li class="collection-item">Glaxo: Hann, M.; Hudson, B.; Lewell, X.; Lifely, R.; Miller, L.; Ramsden, N. Strategic Pooling of Compounds for High-Throughput Screening. J. Chem. Inf. Model. 1999, 39, 897−902., <a href="https://doi.org/10.1021/ci990423o" target="_blank">https://doi.org/10.1021/ci990423o</a></li>
            <li class="collection-item">Inpharmatica: Inpharmatica Inc., <a href="http://www.inpharmatica.co.uk" target="_blank">http://www.inpharmatica.co.uk</a></li>
            <li class="collection-item">Lint: Blake, J. F. Identification and evaluation of molecular properties related to preclinical optimization and clinical fate. Med. Chem. (Sharjah, United Arab Emirates) 2005, 1, 649−655., <a href="https://doi.org/10.2174/157340605774598081" target="_blank">https://doi.org/10.2174/157340605774598081</a></li>
            <li class="collection-item">Mlsmr: <a href="https://www.yumpu.com/en/document/view/12367541/mlsmr-excluded-functionality-filters-nih-molecular-libraries-" target="_blank">https://www.yumpu.com/en/document/view/12367541/mlsmr-excluded-functionality-filters-nih-molecular-libraries-</a> </li>
            <li class="collection-item">PAINS:  Baell, J. B., Holloway, G. A. New Substructure Filters for Removal of Pan Assay Interference Compounds (PAINS) from Screening Libraries and for their Exclusion in Bioassays, J Med Chem, 2010 , 53, <a href="https://doi.org/10.1021/jm901137j" target="_blank">https://doi.org/10.1021/jm901137j</a></li>
            <li class="collection-item">Smartscyp: Rydberg, P.; Gloriam, D. E.; Zaretzki, J.; Breneman, C.; Olsen, L. SMARTCyp: A 2D method for prediction of cytochrome P450-mediated drug metabolism. ACS Med. Chem. Lett. 2010, 1, 96−100., <a href="https://doi.org/10.1021/ml100016x" target="_blank">https://doi.org/10.1021/ml100016x</a></li>
            <li class="collection-item">SureChEMBL: ToxAlerts: A Web Server of Structural Alerts for Toxic Chemicals and Compounds with Potential Adverse Reactions Iurii Sushko, Elena Salmina, Vladimir A. Potemkin, Gennadiy Poda, and Igor V. Tetko Journal of Chemical Information and Modeling 2012 52 (8), 2310-2316, <a href="https://www.surechembl.org/knowledgebase/169485-non-medchem-friendly-smarts" target="_blank">https://www.surechembl.org/knowledgebase/169485-non-medchem-friendly-smarts</a></li>
        </ul>
    </modal>
`,
};

/**
 * The similarity info button of the app, containing information about the two implemented similarity modes.
 */
const SimilarityInfoButton = {
    name: 'SimilarityInfoButton',
    components: { Modal },
    template: `
    <modal id="similarity-modal" button-text="Info">
        <h4>Similarity Mode</h4>
        <ul class="collection">
            <li class="collection-item">Subset:<br> Subpattern Isomorphism is implemented. Every node in the more generic pattern has to have a match in the other pattern. Similarity between matched nodes is calculated.</li>
            <li class="collection-item">Similarity:<br> A maximum connected subgraph between the two patterns is determined and the similarity between matched nodes is calculated.</li>
        </ul>
    </modal>
`,
};


export { Modal, AboutButton, ControlsButton, InfoButton, SimilarityInfoButton };
